<template>
  <div class="intro">
    <div class="info-small-box">
      <h3 id="contact" class="poppins-medium">Contact me</h3>
      <div class="line"></div>
      <div class="contact-me-box">
        <img
          src="../assets/contact-me.png"
          alt="contact-me-photo"
          class="contact-me-photo"
        />
        <form @submit.prevent="submitForm">
          <div
            class="contact-form"
            :class="{ invalid: nameValidity === 'invalid' }"
          >
            <input
              name="name"
              type="text"
              v-model="name"
              @blur="validateInput"
              placeholder="Name*"
              class="input-box poppins-medium"
            />
          </div>
          <div
            class="contact-form"
            :class="{ invalid: emailValidity === 'invalid' }"
          >
            <input
              type="email"
              placeholder="Email*"
              class="input-box poppins-medium"
              @blur="validateEmailInput"
              v-model="email"
            />
          </div>
          <div
            class="contact-form"
            :class="{ invalid: textValidity === 'invalid' }"
          >
            <textarea
              placeholder="Message*"
              class="text-box poppins-medium"
              rows="4"
              cols="50"
              @blur="validateTextInput"
              v-model="message"
            ></textarea>
          </div>
          <button class="social-button">Send</button>
        </form>
      </div>
      <div class="social">
        <a href="https://github.com/moretsolange" target="_blank"
          ><img src="../assets/social/github.png" class="social-img"
        /></a>
        <a href="https://www.linkedin.com/in/moretsolange/" target="_blank"
          ><img src="../assets/social/linkedin.png" class="social-img"
        /></a>
        <a
          href="https://drive.google.com/file/d/12eITIdU_IqgeJLXPSOTNFf-GphNaZFMd/view?usp=sharing"
          target="_blank"
        >
          <img src="../assets/social/cv.png" class="social-img"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import db from "../firebase/config.js";
import { collection, addDoc } from "firebase/firestore";

export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      nameValidity: "pending",
      emailValidity: "pending",
      textValidity: "pending",
    };
  },
  methods: {
    async submitForm() {
      let userMessage = {
        name: this.name,
        email: this.email,
        message: this.message,
      };
      try {
        await addDoc(collection(db, "userMessages"), userMessage);
        alert("Üzenet sikeresen elküldve!");

        this.name = "";
        this.email = "";
        this.message = "";

        this.nameValidity = "pending";
        this.emailValidity = "pending";
        this.textValidity = "pending";
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    },
    validateInput() {
      if (this.name === "") {
        this.nameValidity = "invalid";
      } else {
        this.nameValidity = "valid";
      }
    },
    validateEmailInput() {
      if (this.email === "") {
        this.emailValidity = "invalid";
      } else {
        this.emailValidity = "valid";
      }
    },
    validateTextInput() {
      if (this.message === "") {
        this.textValidity = "invalid";
      } else {
        this.textValidity = "valid";
      }
    },
  },
};
</script>

<style scoped>
.contact-me-box {
  text-align: center;
  display: flex;
  margin-top: 25px;
}

.contact-me-photo {
  height: 400px;
  width: 350px;
  border-radius: 8px;
  margin-right: 35px;
}

.input-box,
.text-box {
  width: 250px;
  border: none;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(44, 50, 64, 0.3),
    -1px -1px 2px rgba(26, 28, 37, 0.5),
    -4px 4px 8px rgba(26, 28, 37, 0.2) inset,
    4px -4px 8px rgba(26, 28, 37, 0.2) inset,
    -4px -4px 8px rgba(44, 50, 64, 0.9) inset,
    4px 4px 10px rgba(26, 28, 37, 0.9) inset;
  box-sizing: border-box;
  font-size: 25px;
  margin-bottom: 25px;
  padding: 25px;
  color: #757575;
}

.input-box {
  height: 49px;
}

.social-button {
  width: 150px;
  height: 55px;
  border: none;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(44, 50, 64, 0.3),
    -1px -1px 2px rgba(26, 28, 37, 0.5),
    -4px 4px 8px rgba(26, 28, 37, 0.2) inset,
    4px -4px 8px rgba(26, 28, 37, 0.2) inset,
    -4px -4px 8px rgba(44, 50, 64, 0.9) inset,
    4px 4px 10px rgba(26, 28, 37, 0.9) inset;
  font-size: 25px;
  margin-bottom: 25px;
  text-align: center;
  color: #757575;
}

.social-button:hover,
.social-button:active {
  background-color: #7733ff;
  color: white;
}

.contact-form.invalid input {
  box-shadow: red 0px 0px 0px 2px, red 0px 4px 6px -1px, red 0px 1px 0px inset;
}

.contact-form.invalid textarea {
  box-shadow: red 0px 0px 0px 2px, red 0px 4px 6px -1px, red 0px 1px 0px inset;
}

.social {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 62px;
}
.social-img {
  width: 35px;
  height: 35px;
}
</style>
