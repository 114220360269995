import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

import IntroPage from "./components/IntroPage.vue";
import MySkills from "./components/MySkills.vue";
import MyProjects from "./components/MyProjects.vue";
import MyEducation from "./components/MyEducation.vue";
import MyCerts from "./components/MyCerts.vue";
import AboutMe from "./components/AboutMe.vue";
import ContactMe from "./components/ContactMe.vue";
import ScrollButton from "./components/ScrollButton.vue";
import TheNavigation from "./components/nav/TheNavigation.vue";
import HamburgerMenu from "./components/nav/HamburgerMenu.vue";

app.component("intro-page", IntroPage);
app.component("my-skills", MySkills);
app.component("my-projects", MyProjects);
app.component("my-education", MyEducation);
app.component("my-certs", MyCerts);
app.component("about-me", AboutMe);
app.component("contact-me", ContactMe);
app.component("scroll-button", ScrollButton);
app.component("the-navigation", TheNavigation);
app.component("hamburger-menu", HamburgerMenu);

app.mount("#app");
