<template>
  <the-navigation></the-navigation>
  <hamburger-menu></hamburger-menu>
  <intro-page></intro-page>
  <about-me></about-me>
  <my-projects></my-projects>
  <my-skills></my-skills>
  <my-education></my-education>
  <my-certs></my-certs>
  <contact-me></contact-me>
  <scroll-button></scroll-button>
</template>

<style>
@import "./style/main.css";
@import "./style/mobile-main.css";
@import "./style/tablet-main.css";
</style>
