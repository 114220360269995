<template>
  <div class="intro">
    <div class="info-small-box">
      <h3 id="education" class="poppins-medium">Education</h3>
      <div class="line"></div>
      <div class="education-info-box">
        <div class="education-box">
          <div class="edu-image">
            <img src="../assets/star.png" alt="star icon" />
          </div>
          <div class="edu-text">
            <span class="edu-span"
              >Udemy – Maximilian Schwartzmüller – Vue The Complete Guide (Incl.
              Router & Composition Api)</span
            >
          </div>
        </div>
        <div class="education-box">
          <div class="edu-image">
            <img src="../assets/star.png" alt="star icon" />
          </div>
          <div class="edu-text">
            <span class="edu-span"
              >Udemy - Maximilian Schwartzmüller - Javascript - The Complete
              Guide 2023 (Beginner + Advanced)</span
            >
          </div>
        </div>
        <div class="education-box">
          <div class="edu-image">
            <img src="../assets/star.png" alt="star icon" />
          </div>
          <div class="edu-text">
            <span class="edu-span"
              >Udemy - Jonas Schmedtmann - The Complete Javascript Course 2023:
              From Zero To Expert!</span
            >
          </div>
        </div>
        <div class="education-box">
          <div class="edu-image">
            <img src="../assets/star.png" alt="star icon" />
          </div>
          <div class="edu-text">
            <span class="edu-span"
              >Udemy - Jonas Schmedtmann - Build Responsive Real-world Websites
              With Html And Css</span
            >
          </div>
        </div>
        <div class="education-box">
          <div class="edu-image">
            <img src="../assets/star.png" alt="star icon" />
          </div>
          <div class="edu-text">
            <span class="edu-span"
              >Udemy - Brad Traversy - Modern Html & Css From The Beginning
              (Including Sass)</span
            >
          </div>
        </div>
        <div class="education-box">
          <div class="edu-image">
            <img src="../assets/star.png" alt="star icon" />
          </div>
          <div class="edu-text">
            <span class="edu-span">Greenfox: My First App</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.education-info-box {
  width: 100%;
  max-width: 700px;
  margin-top: 25px;
}
.education-box {
  display: flex;
  margin-top: 30px;
  text-align: justify;
}

img {
  width: 30px;
  height: 30px;
}

.edu-text {
  margin-left: 25px;
}

.edu-image {
  display: flex;
  align-items: center;
}
</style>
