<template>
  <div class="intro">
    <div class="info-small-box">
      <h3 id="projects" class="poppins-medium">Projects</h3>
      <div class="line"></div>
      <div class="project-container">
        <div class="image-container">
          <div class="image-icon">
            <img
              src="../assets/phasmodex.png"
              class="project-photo-icon"
              alt="phasmodex project"
              @mouseover="displayPhasmoPhoto"
              @mouseleave="hidePhasmoPhoto"
            />
          </div>
          <div class="image-icon">
            <img
              src="../assets/restaurant.png"
              class="project-photo-icon"
              alt="restaurant project"
              @mouseover="displayRestaurantPhoto"
              @mouseleave="hideRestaurantPhoto"
            />
          </div>
          <div class="image-icon">
            <img
              src="../assets/dragon.png"
              class="project-photo-icon"
              alt="dragon project"
              @mouseover="displayMonsterPhoto"
              @mouseleave="hideMonsterPhoto"
            />
          </div>
        </div>
        <div class="big-image-container">
          <div
            v-if="!isHoveredRestaurant && !isHoveredMonster"
            class="image-phasmo"
          >
            <a href="http://phasmodex.org" target="_blank"
              ><img
                v-show="isHoveredPhasmo"
                src="../assets/phasmo.png"
                class="hovered-project-photo"
                alt="hovered project"
            /></a>
          </div>
          <div
            class="image-restaurant"
            v-else-if="!isHoveredPhasmo && !isHoveredMonster"
          >
            <a
              href="https://moretsolange.github.io/vue-restaurant/dist/index.html"
              target="_blank"
              ><img
                v-show="isHoveredRestaurant"
                src="../assets/rest.png"
                class="hovered-project-photo"
                alt="hovered project"
            /></a>
          </div>
          <div class="image-monster" v-else>
            <a
              href="https://moretsolange.github.io/mini-projects/monster-game/index.html"
              target="_blank"
              ><img
                v-show="isHoveredMonster"
                src="../assets/monster.png"
                class="hovered-project-photo"
                alt="hovered project"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const isHoveredPhasmo = ref(false);
    const isHoveredRestaurant = ref(false);
    const isHoveredMonster = ref(false);

    const displayPhasmoPhoto = () => {
      isHoveredPhasmo.value = true;
    };

    const hidePhasmoPhoto = () => {
      setTimeout(() => {
        isHoveredPhasmo.value = false;
        false;
      }, 2000);
    };

    const hideRestaurantPhoto = () => {
      setTimeout(() => {
        isHoveredRestaurant.value = false;
      }, 2000);
    };

    const displayRestaurantPhoto = () => {
      isHoveredRestaurant.value = true;
    };

    const displayMonsterPhoto = () => {
      isHoveredMonster.value = true;
    };

    const hideMonsterPhoto = () => {
      setTimeout(() => {
        isHoveredMonster.value = false;
        false;
      }, 2000);
    };

    return {
      isHoveredPhasmo,
      isHoveredRestaurant,
      isHoveredMonster,
      displayPhasmoPhoto,
      hidePhasmoPhoto,
      displayRestaurantPhoto,
      hideRestaurantPhoto,
      displayMonsterPhoto,
      hideMonsterPhoto,
    };
  },
};
</script>

<style scoped>
.project-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.project-photo-icon {
  width: 50px;
  height: 50px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.image-container {
  height: 331px;
  width: 200px;
}

.big-image-container {
  height: 331px;
  width: 200px;
  display: flex;
  align-items: center;
}
.hovered-project-photo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: fadeIn 0.5s;
}

.image-phasmo {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-end;
}

.image-restaurant {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.image-monster {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
