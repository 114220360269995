<template>
  <div class="intro">
    <div class="about">
      <div class="photo-box">
        <img alt="photo" src="../assets/photo.jpeg" class="photo-of-me" />
      </div>
      <div class="about-me-info">
        <h3 id="about" class="poppins-regular">About me</h3>
        <span class="poppins-light" id="about-me-desc"
          >I’m a Junior Software Developer, with a focus on expanding my
          proficiency in Vue.js and other frontend technologies. I have helped
          in building a website and a non-native android application, and I made
          several projects on my own, that helped me grow my knowledge and test
          my skills. I’m actively seeking opportunities to contribute my skills
          and passion to projects that demand a blend of technical prowess and a
          keen eye for design.</span
        >
      </div>
    </div>
  </div>
</template>
