<template>
  <div class="intro">
    <div class="info-small-box">
      <h3 id="skills" class="poppins-medium">Skills</h3>
      <div class="line"></div>
      <div class="skills-summary">
        <div class="skills-column">
          <button id="skills-btn" class="poppins-medium">Vue.js</button>
          <button id="skills-btn" class="poppins-medium">Nuxt</button>
          <button id="skills-btn" class="poppins-medium">Pinia</button>
        </div>
        <div class="skills-column">
          <button id="skills-btn" class="poppins-medium">Vuex</button>
          <button id="skills-btn" class="poppins-medium">Buefy</button>
          <button id="skills-btn" class="poppins-medium">PrimeVue</button>
        </div>
        <div class="skills-column">
          <button id="skills-btn" class="poppins-medium">HTML</button>
          <button id="skills-btn" class="poppins-medium">JS/TS</button>
          <button id="skills-btn" class="poppins-medium">CSS</button>
        </div>
        <div class="skills-column">
          <button id="skills-btn" class="poppins-medium">Bootstrap</button>
          <button id="skills-btn" class="poppins-medium">Tailwind</button>
          <button id="skills-btn" class="poppins-medium">Figma</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.skills-summary {
  text-align: center;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 22px 0px;
  margin-top: 25px;
}

.skills-column {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 0px 58px;
  max-width: 100%;
}

button {
  height: 50px;
  width: 253px;
  margin-top: 25px;
  border-radius: 33px;
  background-color: #7733ff;
  box-shadow: -2px -2px 10px rgba(255, 255, 255, 0.25),
    2px 2px 10px rgba(0, 0, 0, 0.25);
  border-color: transparent;
  color: white;
  font-size: 18px;
}
</style>
