<template>
  <div>
    <button v-if="showButton" @click="scrollToTop" class="scroll-to-top-button">
      &uarr;
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnMount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Show the button when the user scrolls down a certain amount
      this.showButton = window.pageYOffset > 300;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling behavior
      });
    },
  },
};
</script>
