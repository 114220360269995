<template>
  <div class="hamburger-menu">
    <Slide
      :closeOnNavigation="true"
      width="200"
      disableOutsideClick
      disableEsc
      noOverlay
    >
      <a href="#about">
        <span class="poppins-medium">About</span>
      </a>
      <a href="#projects">
        <span class="poppins-medium">Projects</span>
      </a>
      <a href="#skills">
        <span class="poppins-medium">Skills</span>
      </a>
      <a href="#education">
        <span class="poppins-medium">Education</span>
      </a>
      <a href="#certs">
        <span class="poppins-medium">Certificate</span>
      </a>
      <a href="#contact">
        <span class="poppins-medium">Contact</span>
      </a>
    </Slide>
  </div>
</template>

<script>
import { Slide } from "vue3-burger-menu";
export default {
  components: {
    Slide, // Register your component
  },
};
</script>

<style scoped>
.hamburger-menu {
  display: none;
}

.bm-menu {
  background-color: #7733ff !important;
}
</style>
