import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAa6KogiHvJZ4DQo-N3bnqnq7IiqzOgSLs",
  authDomain: "form-5fc1d.firebaseapp.com",
  databaseURL: "https://form-5fc1d-default-rtdb.firebaseio.com",
  projectId: "form-5fc1d",
  storageBucket: "form-5fc1d.appspot.com",
  messagingSenderId: "359891226870",
  appId: "1:359891226870:web:1c9664760e191f1ff45cd7",
  measurementId: "G-DX8959G0JY",
};

initializeApp(firebaseConfig);

const db = getFirestore();
export default db;
