<template>
  <div class="nav-bar">
    <button class="nav-btn">
      <a href="#about" class="poppins-medium">About</a>
    </button>
    <button class="nav-btn">
      <a href="#projects" class="poppins-medium">Projects</a>
    </button>
    <button class="nav-btn">
      <a href="#skills" class="poppins-medium">Skills</a>
    </button>
    <button class="nav-btn">
      <a href="#education" class="poppins-medium">Education</a>
    </button>
    <button class="nav-btn">
      <a href="#certs" class="poppins-medium">Certificate</a>
    </button>
    <button class="nav-btn">
      <a href="#contact" class="poppins-medium">Contact</a>
    </button>
  </div>
  <img alt="main logo" src="../../assets/logo.png" class="logo-img" />
</template>

<script></script>
