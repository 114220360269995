<template>
  <div class="intro">
    <h3 class="poppins-regular">Hey</h3>
    <h2 id="welcome-msg" class="poppins-bold">I’m Móré Tímea Solange</h2>
    <h4 id="frontend-msg" class="poppins-medium">Frontend Developer</h4>
    <span class="poppins-light">
      A passionate Junior Frontend Developer ready to embark on an exciting
      journey with your team. Below, you'll discover a showcase of my noteworthy
      projects that demonstrate my skills and dedication to creating captivating
      web experiences.
    </span>
  </div>
</template>
