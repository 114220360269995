<template>
  <div class="intro">
    <div class="info-small-box">
      <h3 id="certs" class="poppins-medium">Certifications</h3>
      <div class="line"></div>
      <div class="carousel">
        <Carousel :items-to-show="itemsToShow" :wrap-around="true">
          <Slide v-for="slide in images" :key="slide">
            <img class="carousel__image" :src="slide" alt="Certificate" />
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "WrapAround",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      images: [
        require("../assets/certs/cert1.png"),
        require("../assets/certs/cert2.png"),
        require("../assets/certs/cert3.png"),
        require("../assets/certs/cert4.png"),
        require("../assets/certs/cert5.png"),
      ],
      itemsToShow: 2.5,
    };
  },
  mounted() {
    this.updateItemsToShow();
    window.addEventListener("resize", this.updateItemsToShow);
  },
  methods: {
    updateItemsToShow() {
      if (window.innerWidth <= 768) {
        this.itemsToShow = 1;
      } else {
        this.itemsToShow = 2.5;
      }
    },
  },
  beforeUnMount() {
    window.removeEventListener("resize", this.updateItemsToShow);
  },
});
</script>
<style>
.carousel__image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 50px;
  padding: 5px;
}
</style>
